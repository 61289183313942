import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Box, LoadingOverlay, Paper, Anchor, Center, Stack } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { IMaskInput } from 'react-imask';
import { CountryCode } from 'libphonenumber-js';
import { MutationUpdateCustomerArgs, CustomerInput } from '@/graphql-types';
import {
  GetCustomersDocument,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useBusinessDataByTaxInfoLazyQuery,
} from '@/graphql-operations';
import { FormikInput, FormikSelect } from '@/components/FormikWrapped';
import { showErrorToast } from '@/components/Toast';
import validationSchema, { isValidPortugalNif } from './validationSchema';
import { getPhoneMaskForCountry } from '@/utils/getPhoneMaskForCountry';
import { ONBOARDING_COUNTRIES } from '@/utils';
import { FormikInputProps } from '@/components/FormikWrapped/FormikInput';
import { countriesOptionsCustomer } from '@/utils/getCountriesForCustomer';
import IconCheck from '@/assets/icons/IconCheck.svg?react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { scrollToErrors } from '@/utils/scrollToErrors';
import { Button } from '@/components/ButtonVariants';
type CreateCustomerModalFormProps = {
  defaultFormValues?: MutationUpdateCustomerArgs['customer'];
  style?: React.CSSProperties;
  onSuccess?: (newCustomer: CustomerInput) => void;
  isEditing?: boolean;
  updateCustomerId?: string;
  shouldSaveToDB?: boolean;
};
const CreateCustomerModalForm = ({
  defaultFormValues,
  style,
  onSuccess,
  isEditing,
  updateCustomerId,
  shouldSaveToDB,
}: CreateCustomerModalFormProps) => {
  const [isFirstSubmitAttempt, setIsFirstSubmitAttempt] = useState(false);
  const { t } = useTranslation();
  const [isAdvancedFieldsVisible, setAdvancedFieldsVisibility] = useState(false);
  const [nifNumberFetched, setNifNumberFetched] = useState<string | undefined>(defaultFormValues?.taxpayerNumber);
  const [createCustomerMutation, { loading }] = useCreateCustomerMutation({
    refetchQueries: [GetCustomersDocument],
    onCompleted: (data) => {
      if (onSuccess) {
        onSuccess(data.createCustomer);
      }
    },
    onError: (error) => {
      showErrorToast({ title: t('error.createCustomer'), message: error.message });
      return;
    },
  });
  const [updateCustomerMutation, { loading: updateCustomerLoading }] = useUpdateCustomerMutation({
    refetchQueries: [GetCustomersDocument],
    onCompleted: (data) => {
      if (onSuccess) {
        onSuccess(data.updateCustomer);
      }
    },
    onError: (error) => {
      showErrorToast({ title: t('error.updateCustomer'), message: error.message });
      return;
    },
  });
  const [businessDataByNifRefetch, { loading: businessDataByNifLoading }] = useBusinessDataByTaxInfoLazyQuery({
    fetchPolicy: 'network-only',
  });
  const initialValues = {
    taxpayerNumber: '',
    email: '',
    name: '',
    country: ONBOARDING_COUNTRIES.PT,
    phone: '',
    city: '',
    address: '',
    postalCode: '',
    ...defaultFormValues,
  };
  const handleFormSubmit = (values: MutationUpdateCustomerArgs['customer']) => {
    if (isEditing && updateCustomerId && shouldSaveToDB) {
      updateCustomerMutation({ variables: { updateCustomerId, customer: values } });
      return;
    }
    if (shouldSaveToDB) {
      createCustomerMutation({ variables: { customer: values } });
    } else {
      if (onSuccess) {
        onSuccess({ ...values, id: updateCustomerId });
      }
    }
  };
  const showButton = (
    <Anchor>
      <Center inline className="text-moroccan-blue">
        <IconChevronDown size={20} stroke={2.5} />
        <Box ml={5}>{t('button.label.showAMoreFields')}</Box>
      </Center>
    </Anchor>
  );
  const hideButton = (
    <Anchor>
      <Center inline className="text-moroccan-blue">
        <IconChevronUp size={20} stroke={2.5} />
        <Box ml={5}>{t('button.label.hideAdvancedFields')}</Box>
      </Center>
    </Anchor>
  );
  return (
    <Paper
      p={0}
      shadow="none"
      style={{
        ...style,
        position: 'relative',
        backgroundColor: 'var(--mantine-color-body)',
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={handleFormSubmit}
        validateOnBlur={isFirstSubmitAttempt}
        validateOnChange={isFirstSubmitAttempt}
      >
        {({ errors, touched, values, setFieldValue, setFieldError, validateForm }) => {
          const isCountryPT = values.country === ONBOARDING_COUNTRIES.PT;

          const hasAdvancedFieldsErrors = !!(errors.city || errors.address || errors.postalCode || errors.phone);

          const advancedFieldsShouldBeVisible = isAdvancedFieldsVisible || hasAdvancedFieldsErrors;

          const handleCountryChange = (value: string | null) => {
            setFieldValue('country', value);

            setFieldValue('phone', '');

            setFieldValue('taxpayerNumber', '');
            setNifNumberFetched(undefined);

            if (errors.taxpayerNumber) {
              setFieldError('taxpayerNumber', '');
            }
          };

          const fetchInfoByPTNif = async (nif: string) => {
            const isEnteredValueSameAsPreviouslyFetched = nif === nifNumberFetched;
            if (!nif || isEnteredValueSameAsPreviouslyFetched) return;
            const response = await businessDataByNifRefetch({
              variables: { taxpayerNumber: nif },
            });
            if (response?.data?.businessDataByTaxInfo) {
              const data = response.data.businessDataByTaxInfo;
              setNifNumberFetched(data.nif);
              const existingFormValues = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                city: values.city,
                address: values.address,
                postalCode: values.postalCode,
              };
              setFieldValue('name', data.name || existingFormValues.name);
              setFieldValue('email', data.email || existingFormValues.email);
              setFieldValue('phone', data.phone || existingFormValues.phone);
              setFieldValue('city', data.city || existingFormValues.city);
              setFieldValue('address', data.address || existingFormValues.address);
              setFieldValue('postalCode', data.zip || existingFormValues.postalCode);
            }
          };
          const taxPayerInputProps: Partial<FormikInputProps> = isCountryPT
            ? {
                component: IMaskInput,
                unmask: true,
                inputMode: 'numeric',
                mask: '000 000 000',
                handleOnAccept: async (value) => {
                  if (errors.taxpayerNumber) {
                    setFieldError('taxpayerNumber', '');
                  }
                  await setFieldValue('taxpayerNumber', value);
                  if (await isValidPortugalNif(value)) {
                    fetchInfoByPTNif(value);
                  } else {
                    setNifNumberFetched(undefined);
                  }
                },
              }
            : {
                onChange: (event) => {
                  if (errors.taxpayerNumber) {
                    setFieldError('taxpayerNumber', '');
                  }
                  setFieldValue('taxpayerNumber', event.currentTarget.value);
                },
              };
          const showIconCheck =
            !businessDataByNifLoading &&
            values.taxpayerNumber &&
            isCountryPT &&
            nifNumberFetched === values.taxpayerNumber;
          return (
            <Form autoComplete="off">
              <LoadingOverlay visible={loading || updateCustomerLoading || businessDataByNifLoading} />
              <Collapsible.Root open={advancedFieldsShouldBeVisible} onOpenChange={setAdvancedFieldsVisibility}>
                <Stack gap={12}>
                  <FormikSelect
                    name="country"
                    label={t('label.country')}
                    placeholder={t('label.country')}
                    onChange={handleCountryChange}
                    data={countriesOptionsCustomer(t)}
                    disabled={businessDataByNifLoading}
                    error={errors.country && touched.country ? errors.country : null}
                    searchable
                    clearable
                  />
                  <FormikInput
                    {...taxPayerInputProps}
                    name="taxpayerNumber"
                    type="text"
                    rightSection={showIconCheck ? <IconCheck /> : null}
                    placeholder={isCountryPT ? t('placeholder.nifOrNipc') : t('placeholder.vatNumber')}
                    label={isCountryPT ? t('placeholder.nifOrNipc') : t('label.vatNumber')}
                    error={errors.taxpayerNumber && touched.taxpayerNumber ? errors.taxpayerNumber : null}
                  />
                  <FormikInput
                    name="name"
                    placeholder={t('label.name')}
                    disabled={businessDataByNifLoading}
                    label={t('label.name')}
                    error={errors.name && touched.name ? errors.name : null}
                  />
                  <FormikInput
                    name="email"
                    placeholder={`${t('label.email')} ${t('label.optional')}`}
                    label={`${t('label.email')} ${t('label.optional')}`}
                    disabled={businessDataByNifLoading}
                    error={errors.email && touched.email ? errors.email : null}
                  />
                  <Collapsible.Trigger asChild>{!advancedFieldsShouldBeVisible && showButton}</Collapsible.Trigger>
                </Stack>
                <Collapsible.Content>
                  <Stack gap={12} mt={12}>
                    <FormikInput
                      name="city"
                      label={`${t('label.city')} ${t('label.optional')}`}
                      placeholder={`${t('label.city')} ${t('label.optional')}`}
                      disabled={businessDataByNifLoading}
                      error={errors.city && touched.city ? errors.city : null}
                    />
                    <FormikInput
                      name="address"
                      label={`${t('label.address')} ${t('label.optional')}`}
                      placeholder={`${t('label.address')} ${t('label.optional')}`}
                      disabled={businessDataByNifLoading}
                      error={errors.address && touched.address ? errors.address : null}
                    />
                    {isCountryPT ? (
                      <FormikInput
                        name="postalCode"
                        component={IMaskInput}
                        mask="0000-000"
                        inputMode="numeric"
                        handleOnAccept={(value) => {
                          setFieldValue('postalCode', value);
                        }}
                        disabled={businessDataByNifLoading}
                        label={`${t('label.postalCode')} ${t('label.optional')}`}
                        placeholder={`${t('label.postalCode')} ${t('label.optional')}`}
                        error={errors.postalCode && touched.postalCode ? errors.postalCode : null}
                      />
                    ) : (
                      <FormikInput
                        name="postalCode"
                        label={`${t('label.postalCode')} ${t('label.optional')}`}
                        placeholder={`${t('label.postalCode')} ${t('label.optional')}`}
                        disabled={businessDataByNifLoading}
                        error={errors.postalCode && touched.postalCode ? errors.postalCode : null}
                      />
                    )}
                    <FormikInput
                      name="phone"
                      label={`${t('label.phone')} ${t('label.optional')}`}
                      component={IMaskInput}
                      inputMode="numeric"
                      disabled={businessDataByNifLoading}
                      mask={getPhoneMaskForCountry(values.country as CountryCode)}
                      placeholder={`${t('label.phone')} ${t('label.optional')}`}
                      error={errors.phone && touched.phone ? errors.phone : null}
                      handleOnAccept={(value) => {
                        setFieldValue('phone', value);
                      }}
                    />
                    <Collapsible.Trigger asChild>{hideButton}</Collapsible.Trigger>
                  </Stack>
                </Collapsible.Content>
              </Collapsible.Root>
              <Button
                type="submit"
                fullWidth
                onClick={async () => {
                  setIsFirstSubmitAttempt(true);
                  const formErrors = await validateForm();
                  scrollToErrors(formErrors);
                }}
                variant="primary"
                size="m"
                className="mt-[32px]"
              >
                {isEditing ? t('button.label.save') : t('button.label.create')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};
export default CreateCustomerModalForm;
