import { Skeleton } from '@mantine/core';

type SkeletonListCardProps = {
  type: 'invoices' | 'customers' | 'items';
};

export default function SkeletonListCard({ type }: SkeletonListCardProps) {
  return (
    <div className="space-y-3">
      {[1, 2, 3].map((i) => (
        <>
          {type === 'invoices' && (
            <div key={i} className="flex items-center justify-between rounded-3xl bg-gray-100 p-4 sm:p-6">
              <div className="flex items-center gap-3">
                <Skeleton className="h-[38px] w-[38px] rounded-[10px]" />

                <div className="space-y-1.5">
                  <Skeleton className="h-4 w-24 xs:w-32" />
                  <Skeleton className="h-3 w-16 xs:w-20" />
                </div>
              </div>

              <div className="justify-items-end space-y-1">
                <Skeleton className="h-3 w-20" />
                <Skeleton className="h-5 w-16 rounded-full" />
              </div>
            </div>
          )}

          {type === 'customers' && (
            <div key={i} className="flex items-center justify-between rounded-3xl bg-gray-100 p-4 sm:p-6">
              <div className="flex items-center gap-3">
                <Skeleton className="h-[38px] w-[38px] rounded-[10px]" />

                <div className="space-y-1.5">
                  <Skeleton className="h-4 w-24 xs:w-32" />
                  <Skeleton className="h-3 w-16 xs:w-20" />
                </div>
              </div>
            </div>
          )}

          {type === 'items' && (
            <div className="rounded-3xl bg-gray-100 p-4 sm:p-6">
              <div className="flex items-center justify-between border-b border-solid border-gray-200 pb-3">
                <Skeleton className="h-4 w-24 xs:w-32" />
              </div>
              <div className="pb-1 pt-3">
                <Skeleton className="h-3 w-24 xs:w-12" />
              </div>
              <div className="flex items-center justify-between">
                <Skeleton className="h-4 w-20 xs:w-24" />
                <Skeleton className="h-5 w-[72px] rounded-full" />
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
}
