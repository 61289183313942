import { Customer } from '@/graphql/types';
import { Avatar, AvatarProps } from '@mantine/core';
import IconCustomerDark from '@/assets/icons/IconCustomerDark.svg?react';
import classes from './CustomerAvatar.module.css';

const AVATAR_COLORS = ['#87BFFF', '#9E83FB', '#48C77F', '#FFA662', '#F86D60'];

export type CustomerAvatarProps = Omit<AvatarProps, 'classNames'> & {
  customer: Partial<Customer>;
};

export function CustomerAvatar({ customer, ...props }: CustomerAvatarProps) {
  const defaultProps: Partial<AvatarProps> = {
    size: 30,
    radius: 10,
    allowedInitialsColors: AVATAR_COLORS,
    autoContrast: false,
    ...props,
  };

  // TODO: find a better way to detect consumidor final
  if (customer.name === 'Consumidor final' && !customer.taxpayerNumber) {
    return (
      <Avatar {...defaultProps}>
        <IconCustomerDark className="h-[72%]" />
      </Avatar>
    );
  }

  return (
    <Avatar
      {...defaultProps}
      color="initials"
      name={customer!.name}
      classNames={{ root: classes.root, placeholder: classes.placeholder }}
    />
  );
}
