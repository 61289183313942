import { Flex, Skeleton, Table } from '@mantine/core';

const SimpleTableSkeletonLoading = ({ withFiltersSkeleton = false }) => {
  return (
    <>
      {withFiltersSkeleton && (
        <Flex mih={50} gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
          <Skeleton height={30} width={300} mb={20} />
          <Skeleton height={30} width={80} mb={20} />
          <Skeleton height={30} width={80} mb={20} />
        </Flex>
      )}
      <Table.ScrollContainer minWidth={650}>
        <Table>
          <Table.Thead>
            <Table.Tr className="border-b-0">
              {Array.from({ length: 6 }, (_, index) => (
                <Table.Th key={index} className="bg-alice-blue first:rounded-l-md last:rounded-r-md">
                  <Skeleton height={40} />
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Array.from({ length: 10 }, (_, index) => (
              <Table.Tr key={index}>
                {Array.from({ length: 6 }, (_, index) => (
                  <Table.Td key={index}>
                    <Skeleton height={20} radius="xl" />
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
};

export default SimpleTableSkeletonLoading;
