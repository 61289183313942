import { FormikErrors } from 'formik';

export const scrollToErrors = (errors: FormikErrors<unknown>, customElements: Record<string, string> = {}) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length > 0) {
    const errorKey = errorKeys[0];
    const customElementId = customElements[errorKey];
    const errorElement = customElementId
      ? document.querySelector(`[data-validation-id="${customElementId}"]`)
      : document.getElementById(customElementId || errorKeys[0]);
    errorElement?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  }
};
