const TEST_NIF_WHITELIST = ['599999993'];
const NIF_BLACKLIST = ['000000000', '999999990'];

export const validateNifControlDigit = (nif?: string) => {
  if (!nif) {
    return true;
  }

  if (NIF_BLACKLIST.includes(nif)) {
    return false;
  }

  let added = 0;
  for (let i = 0; i < 8; i++) {
    added += parseInt(nif[i]) * (9 - i);
  }

  const control = added % 11 <= 1 ? 0 : 11 - (added % 11);

  return parseInt(nif[8]) === control;
};

export const isNIPC = (nif?: string | null): boolean => {
  if (!nif || nif.length !== 9) return false;

  const supportedStartDigits = new Set(['5']);
  return supportedStartDigits.has(nif.charAt(0));
};

export const isNIF = (nif?: string | null): boolean => {
  if (!nif || nif.length !== 9) return false;

  if (import.meta.env.VITE_APP_ENV !== 'production' && TEST_NIF_WHITELIST.includes(nif)) {
    return true;
  }

  const supportedStartDigits = new Set(['1', '2', '3']);
  return supportedStartDigits.has(nif.charAt(0));
};

export const isSupportedNIFNIPC = (nif?: string | null): boolean => {
  if (!nif || nif.length !== 9) return false;

  if (import.meta.env.VITE_APP_ENV !== 'production' && TEST_NIF_WHITELIST.includes(nif)) {
    return true;
  }

  const supportedStartDigits = new Set(['1', '2', '3', '5']);
  return supportedStartDigits.has(nif.charAt(0));
};

export const nifPattern = /^\d{9}$/;
