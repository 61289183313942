import { Group, Text } from '@mantine/core';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Customer } from '@/graphql-types';
// import { IconMail, IconPhone } from '@tabler/icons-react';
import { DEFAULT_COUNTRY } from '@/utils';

import { CustomerAvatar } from '../CustomerAvatar/CustomerAvatar';

type CustomerListCardProps = {
  name: Customer['name'];
  country: Customer['country'];
  taxpayerNumber: Customer['taxpayerNumber'];
  handleCustomerEdit: MouseEventHandler<HTMLDivElement>;
};

export function CustomerListCard({ name, country, taxpayerNumber, handleCustomerEdit }: CustomerListCardProps) {
  const { t } = useTranslation();

  let customerTaxInfo = '';
  if (country === DEFAULT_COUNTRY) {
    customerTaxInfo = taxpayerNumber ? `${country} ${taxpayerNumber}` : t('label.noNif');
  } else {
    customerTaxInfo = taxpayerNumber ? `${country} ${taxpayerNumber}` : country || '';
  }

  return (
    <div className="flex cursor-pointer flex-col justify-between" onClick={handleCustomerEdit}>
      <div className="overflow-hidden rounded-3xl bg-white-vanilla transition-transform duration-300 ease-in-out hover:-translate-y-px">
        <div className="p-4 sm:p-6">
          <div className="flex items-center justify-between">
            <Group gap="sm" wrap="nowrap">
              <CustomerAvatar customer={{ name }} size={42} />
              <div>
                <Text className="text-md line-clamp-1 font-semibold">{name || '—'}</Text>
                <Text className="text-[11px] text-dark-bronze">{customerTaxInfo}</Text>
              </div>
            </Group>
          </div>
          {/* <div className="flex items-center justify-between pt-5">
            <div className="flex items-center gap-3 text-sm text-dark-bronze">
              <IconMail size={22} />
              <span>{email || '—'}</span>
            </div>
          </div>
          <div className="flex items-center justify-between pt-1">
            <div className="flex items-center gap-3 text-sm text-dark-bronze">
              <IconPhone size={22} />
              <span>{phone || '—'}</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
