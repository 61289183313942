import { useTranslation } from 'react-i18next';
import { TextInput, rem, Group, Button, CloseButton, Tooltip, SegmentedControl } from '@mantine/core';
import { IconSearch, IconFilterPlus, IconLayoutRows, IconLayoutGrid } from '@tabler/icons-react';
import { DISPLAY_VIEW } from '../constants';
import type { SearchFilterDisplayGroupProps } from '../types';

const SearchFilterDisplayGroup: React.FC<SearchFilterDisplayGroupProps> = ({
  searchTextInputPlaceholder,
  inputSearchText,
  onSearchInputChange,
  onClearSearchClick,
  isCardView,
  onTableCardViewChange,
  withDisplayViewToggle = true,
  isSearchFieldDisabled = false,
  showFilterButton = true,
  showSearchField = true,
}) => {
  const { t } = useTranslation();
  return (
    <Group justify="space-between">
      <Group>
        {showSearchField && (
          <TextInput
            placeholder={searchTextInputPlaceholder}
            disabled={isSearchFieldDisabled}
            leftSectionPointerEvents="none"
            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} />}
            style={{ width: 246 }}
            size="xs"
            value={inputSearchText}
            onChange={onSearchInputChange}
            rightSection={
              <CloseButton
                aria-label={t('clear')}
                onClick={onClearSearchClick}
                style={{ display: inputSearchText ? undefined : 'none' }}
                size="xs"
              />
            }
          />
        )}
        {showFilterButton && (
          <Button
            leftSection={<IconFilterPlus size={14} />}
            variant="default"
            size="xs"
            style={{ borderStyle: 'dashed' }}
          >
            {t('button.label.filter')}
          </Button>
        )}
      </Group>
      {withDisplayViewToggle && (
        <Group>
          <Tooltip.Group openDelay={1000}>
            <SegmentedControl
              size="xs"
              data={[
                {
                  label: (
                    <Tooltip label={t('segmentController.label.tableView')} position="bottom" offset={10}>
                      <IconLayoutRows
                        style={{
                          width: rem(16),
                          height: rem(16),
                          display: 'block',
                        }}
                        stroke={1.5}
                      />
                    </Tooltip>
                  ),
                  value: DISPLAY_VIEW.TABLE,
                },
                {
                  label: (
                    <Tooltip label={t('segmentController.label.cardView')} position="bottom" offset={10}>
                      <IconLayoutGrid
                        style={{
                          width: rem(16),
                          height: rem(16),
                          display: 'block',
                        }}
                        stroke={1.5}
                      />
                    </Tooltip>
                  ),
                  value: DISPLAY_VIEW.CARD,
                },
              ]}
              value={isCardView ? DISPLAY_VIEW.CARD : DISPLAY_VIEW.TABLE}
              onChange={onTableCardViewChange}
            />
          </Tooltip.Group>
          {/* <Button leftSection={<IconAdjustmentsHorizontal size={14} />} variant="default" size="xs">
            {t('button.label.display')}
          </Button> */}
        </Group>
      )}
    </Group>
  );
};

export default SearchFilterDisplayGroup;
