import { getData } from 'iso-3166-1-alpha-2';
import { TFunction } from 'i18next';

const translateCountry = (countryCode: string, t: TFunction): string => {
  return t(`country.${countryCode}`, { defaultValue: getData()[countryCode] || '' });
};

const priorityCountries = [
  'PT',
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'GR',
  'ES',
  'FI',
  'FR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'RO',
  'SE',
  'SI',
  'SK',
];

const allCountries = getData();

const filteredCountries = Object.keys(allCountries).filter((country) => !priorityCountries.includes(country));

export const countriesOptionsCustomer = (t: TFunction) =>
  [...priorityCountries, ...filteredCountries].map((countryCode) => ({
    value: countryCode,
    label: translateCountry(countryCode, t),
  }));
