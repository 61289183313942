import cx from 'classnames';
import { Table } from '@mantine/core';
import type { SearchFilterDisplayGroupProps, TableProps } from './types';

type SimpleDataTableProps = {
  withSearchFilterDisplay?: boolean;
  searchFilterDisplayPropsBag?: SearchFilterDisplayGroupProps;
  tablePropsBag: TableProps;
  hasItem?: boolean;
  hasRightSection?: boolean;
};

const SimpleDataTable = ({ tablePropsBag, hasRightSection }: SimpleDataTableProps) => {
  const { tableProps, headerColumnNames, rowsData, tableHeaderClassNames, tableBodyClassNames } = tablePropsBag;

  return (
    <>
      <Table.ScrollContainer minWidth={650}>
        <Table
          verticalSpacing="13px"
          {...tableProps}
          highlightOnHover
          // striped="odd"
          // stripedColor="#FBFAF9"
          striped="even"
          stripedColor="#f5f2f0"
          className="border-separate border-spacing-y-3"
        >
          <Table.Thead>
            <Table.Tr className={cx('border-b-0', tableHeaderClassNames)}>
              {headerColumnNames.map((headerColumnName, index) => (
                <Table.Th
                  key={headerColumnName}
                  className={cx('bg-light-black-magic px-5 text-white first:rounded-l-[16px] last:rounded-r-[16px]', {
                    'text-right': hasRightSection && index === headerColumnNames.length - 1,
                  })}
                >
                  {headerColumnName}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody className={tableBodyClassNames}>{rowsData}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
};

export default SimpleDataTable;
