import examples from 'libphonenumber-js/mobile/examples';
import { getExampleNumber, CountryCode } from 'libphonenumber-js';

function formatCallingCode(callingCode: string): string {
  if (callingCode.startsWith('1')) {
    return `+1 ${callingCode.slice(1)}`.trim();
  }
  return `+${callingCode}`;
}

export const getPhoneMaskForCountry = (countryCode: CountryCode) => {
  const phoneNumber = getExampleNumber(countryCode, examples);

  if (!phoneNumber) {
    console.error('missing example for', countryCode);
    return '';
  }

  const internationalExample = phoneNumber.formatInternational();
  const callingCodeExample = phoneNumber.countryCallingCode;

  let count = 0;
  const remainder = internationalExample
    .replace(/\d/g, (_match, offset, string) => {
      const digit = callingCodeExample[count];
      count++;
      if (digit) {
        return '';
      } else {
        return string[offset]; // no change
      }
    })
    .replace(/[^0-9 ]/g, '')
    .trim();

  const formattedCallingCode = formatCallingCode(callingCodeExample);
  const mask = `+(${formattedCallingCode.slice(1).replace(/0/g, '\\0')}) ${remainder.replace(/[0-9]/g, '0')}`;

  return mask;
};
