import { Group, Highlight, SimpleGrid, Space, Table, Text } from '@mantine/core';
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerAvatar } from '@/components/Customers/CustomerAvatar/CustomerAvatar';
import SimpleDataTable, { DISPLAY_VIEW, SimpleTableSkeletonLoading } from '@/components/SimpleDataTable';
import SearchFilterDisplayGroup from '@/components/SimpleDataTable/SearchFilterDisplayGroup';
import SkeletonListCard from '@/components/SkeletonListCard/SkeletonListCard';
import { GetCustomersQueryResult, useGetCustomersQuery } from '@/graphql-operations';
import { Customer } from '@/graphql-types';

import { CustomerListCard } from '../CustomerListCard/CustomerListCard';

type CustomerListTableProps = {
  handleCustomerEdit: (customer: Customer) => void;
  loading: boolean;
};

type GetDataRowsProps = {
  data: GetCustomersQueryResult['data'];
  debouncedSearch: string;
  handleCustomerEdit: (customer: Customer) => void;
};

const getDataRows = (
  data: GetDataRowsProps['data'],
  debouncedSearch: GetDataRowsProps['debouncedSearch'],
  handleCustomerEdit: GetDataRowsProps['handleCustomerEdit']
) => {
  return data?.customers?.map((customer, index) => (
    <Table.Tr
      key={customer!.id}
      className={cn('cursor-pointer border-none', { 'bg-angel-feather': index % 2, 'bg-white-vanilla': !(index % 2) })}
      onClick={() => handleCustomerEdit(customer)}
    >
      <Table.Td className="min-w-[150px] rounded-l-2xl px-5 py-4">
        <Group gap="sm">
          <CustomerAvatar customer={customer} />
          <Highlight
            highlight={debouncedSearch}
            className="truncate text-sm font-normal md:max-w-[115px] lg:max-w-[180px] xl:max-w-[300px]"
          >
            {customer.name || '—'}
          </Highlight>
        </Group>
      </Table.Td>

      <Table.Td className="min-w-[100px] px-5 py-4">
        <Text className="text-sm font-normal">{customer!.taxpayerNumber || '—'}</Text>
      </Table.Td>

      <Table.Td className="min-w-[100px] px-5 py-4">
        <Highlight highlight={debouncedSearch} className="text-sm font-normal">
          {customer!.email || '—'}
        </Highlight>
      </Table.Td>

      <Table.Td className="px-5 py-4 rounded-r-2xl">
        <Text className="text-sm font-normal">{customer!.phone || '—'}</Text>
      </Table.Td>
    </Table.Tr>
  ));
};

const CustomerListTable = ({ handleCustomerEdit, loading }: CustomerListTableProps) => {
  const isLargeScreen = useMediaQuery('(min-width: 71em)');
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const { data, error } = useGetCustomersQuery({
    variables: {
      searchParams: { search: debouncedSearch },
      query: {
        system: false,
      },
    },
  });
  const [isCardView, setCardView] = useState(false);

  if (loading)
    return isLargeScreen ? <SimpleTableSkeletonLoading withFiltersSkeleton /> : <SkeletonListCard type="customers" />;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <SearchFilterDisplayGroup
        searchTextInputPlaceholder={t('placeholder.searchCustomersInput')}
        inputSearchText={search}
        onSearchInputChange={(event) => setSearch(event.currentTarget.value)}
        onClearSearchClick={() => setSearch('')}
        isCardView={isCardView}
        onTableCardViewChange={(value) => setCardView(value === DISPLAY_VIEW.CARD)}
        // withDisplayViewToggle={!!isLargeScreen}
        withDisplayViewToggle={false}
        showSearchField={false}
        showFilterButton={false}
      />

      {isCardView || isLargeScreen === false ? (
        <>
          <SimpleGrid cols={{ xs: 1, sm: 1, md: 1, xl: 3 }} className="gap-2">
            {data?.customers?.map((customer) => (
              <CustomerListCard
                key={customer.id}
                handleCustomerEdit={() => handleCustomerEdit(customer)}
                name={customer.name}
                taxpayerNumber={customer.taxpayerNumber}
                country={customer.country}
              />
            ))}
          </SimpleGrid>
        </>
      ) : (
        <SimpleDataTable
          tablePropsBag={{
            headerColumnNames: [t('label.name'), t('label.nif'), t('label.email'), t('label.phone')],
            rowsData: getDataRows(data, debouncedSearch, handleCustomerEdit) || [],
          }}
        />
      )}

      <Space h="md" />
    </>
  );
};

export default CustomerListTable;
