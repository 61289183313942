import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ONBOARDING_COUNTRIES, PT_POSTAL_CODE_REGEX } from '@/utils';
import { validateNifControlDigit, nifPattern, isNIPC, isNIF } from '@/utils/nifNipc';
import { Tenant_Type } from '@/graphql-types';

export const portugalNifSchema = Yup.string().matches(nifPattern).test('nif-control-digit', validateNifControlDigit);
export const isValidPortugalNif = (nif: string, type?: Tenant_Type, t?: TFunction) => {
  let v = portugalNifSchema;

  if (type === Tenant_Type.Company && t) {
    v = v.test('isNIPC', t('validation.NipcMustStartWith'), isNIPC);
  }

  if (type === Tenant_Type.Individual && t) {
    v = v.test('isNif', t('validation.NifMustStartWith'), isNIF);
  }

  return v.isValid(nif).catch(() => false);
};

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    taxpayerNumber: Yup.string().when('country', {
      is: ONBOARDING_COUNTRIES.PT,
      then: () =>
        Yup.string()
          .matches(nifPattern, t('validation.invalidNifFormat'))
          .test('nif-control-digit', t('validation.invalidNifOrNipc'), validateNifControlDigit),
      otherwise: () => Yup.string().max(30, t('validation.nifTooLong')),
    }),
    email: Yup.string().email(t('validation.invalidEmailFormat')).max(60, t('validation.emailTooLong')),
    phone: Yup.string(),
    name: Yup.string().when('taxpayerNumber', {
      is: (taxpayerNumber: string) => !taxpayerNumber || taxpayerNumber.trim() === '',
      then: (schema) => schema.required(t('validation.nameRequired')).max(100, t('validation.nameTooLong')).trim(),
      otherwise: (schema) => schema.max(100, t('validation.nameTooLong')).trim(),
    }),
    country: Yup.string().required(t('validation.countryRequired')),
    city: Yup.string().max(50, t('validation.cityTooLong')),
    address: Yup.string().max(210, t('validation.addressTooLong')),
    postalCode: Yup.string().when('country', {
      is: ONBOARDING_COUNTRIES.PT,
      then: () => Yup.string().matches(PT_POSTAL_CODE_REGEX, t('validation.postalCodeFormat')),
      otherwise: () => Yup.string().max(20, t('validation.postalCodeTooLong')),
    }),
  });

export default validationSchema;
